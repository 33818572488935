<template>
  <box
    elevation="1"
    :class="[
      'assignments-list-item',
      `--${variation}`,
      { '--unreleased': unreleased },
    ]"
    :elevation-hover="unreleased ? '1' : '3'"
  >
    <div class="assignments-list-item__details">
      <slot name="info">
        <span>{{ assignment.subtitle }}</span>
        <h6>
          {{ truncatedTitle }}
        </h6>
      </slot>
    </div>
    <div
      v-if="unreleased"
      class="assignments-list-item__release-date"
    >
      <icon
        class="assignments-list-item__release-date__icon"
        type="calendar"
      />
      <p class="assignments-list-item__release-date__text">
        {{ formattedReleaseDate }}
      </p>
    </div>
    <slot v-else />
  </box>
</template>

<script>
import { localizedDayAndMonth } from '@/utils/date'
import truncateText from '@/mixins/truncateText'

const variations = [ 'primary', 'secondary' ]

export default {
  name: 'AssignmentsListItem',
  mixins: [ truncateText ],
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    variation: {
      type: String,
      default: 'primary',
      validator: (value) => variations.includes(value),
    },
  },
  computed: {
    unreleased() {
      return this.assignment.status === 'unreleased'
    },
    formattedReleaseDate() {
      return localizedDayAndMonth(this.assignment.releaseDate)
    },
    truncatedTitle() {
      const { assignment: { title } } = this

      return this.truncateText(title)
    },
  },
}
</script>

<style lang="scss" scoped>
.assignments-list-item {
  @include flex-center-start;
  @include space-stack($size-s);
  padding: $size-s;
  flex-wrap: wrap;

  @include mq-xs--mf {
    flex-wrap: nowrap;
  }

  @include mq-m--mf {
    padding: $size-s $size-m;
  }

  &__details {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-m--mf {
      padding-right: $size-m;
      flex-grow: 1;
      flex-basis: auto;
    }

    span {
      display: block;
      color: $color-ink-lighter;
      margin-bottom: $size-xs;
    }

    h6 {
      margin-bottom: $size-s;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include mq-m--mf {
        margin-bottom: 0;
      }
    }
  }

  &__release-date {
    display: flex;
    align-items: flex-end;

    ::v-deep &__icon {
      min-width: 24px;
      color: $color-ink-lighter;
      margin-right: $size-xxs;
    }

    &__text {
      white-space: nowrap;
      color: $color-ink-light;
    }
  }

  &.--secondary {
    background-image: url('../../assets/pink-mask-sm.svg') !important;

    @include mq-m--mf {
      background-image: url('../../assets/pink-mask.svg') !important;
    }

    &__details {
      box-sizing: content-box;
      padding: 0;
    }

    &.sas-box {
      min-height: 100px;
      background-size: cover !important;
      background-position: right !important;
      background-repeat:no-repeat !important;
      padding: 0 $size-s;
    }
  }
}
</style>
