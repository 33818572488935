import { parseISO, parse, format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import localePtBr from 'date-fns/locale/pt-BR'

const formatDate = (date, pattern, timeZone = 'Brasilia Summer Time') => {
  const parsedDate = parse(date)
  const zonedDate = zonedTimeToUtc(parsedDate, timeZone)

  return date
    ? format(zonedDate, pattern, { locale: localePtBr })
    : '-'
}

const formatIsoDateWithTimeZone = (date, pattern, timeZone = 'Brasilia Summer Time') => {
  const parsedDate = parseISO(date)
  const zonedDate = zonedTimeToUtc(parsedDate, timeZone)

  return date
    ? format(zonedDate, pattern, { locale: localePtBr })
    : '-'
}

const formatIsoDate = (date, pattern) => (date
  ? format(parseISO(date), pattern, { locale: localePtBr })
  : '-')

const localizedDayAndMonth = (date) => {
  const formattedString = formatIsoDate(date, 'PPP')

  return formattedString.replace(/( de )?\d{4}$/, '')
}

export {
  formatDate,
  formatIsoDate,
  formatIsoDateWithTimeZone,
  localizedDayAndMonth,
}
